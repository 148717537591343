import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { AuthorizationsApiService } from '@cogent/client/shared/services/api/authorizations-api.service';
import { AuthorizationInputArgs, AuthorizationReviewTrigger, CostLine, RepairType, WorkOrderLineAuthorizationRepairItem } from '@upkeeplabs/models/cogent';

@Component({
    selector: 'app-select-repair-type',
    standalone: true,
    imports: [CommonModule, MatButtonModule],
    templateUrl: './select-repair-type.component.html',
    styleUrl: './select-repair-type.component.scss'
})
export class SelectRepairTypeComponent implements OnChanges {

    @Input() repairTypeId: string;
    @Output() repairTypeIdChange: EventEmitter<string> = new EventEmitter();
    @Input() authoInput: AuthorizationInputArgs;

    repairTypes: RepairType[];
    filteredRepairTypes: RepairType[];
    groups: GroupedRepairType[];

    constructor(private authoApi: AuthorizationsApiService) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.authoInput?.currentValue) {
            this.load();
        }
    }

    async load() {
        this.repairTypes = await this.authoApi.getRepairTypesForWorkOrderItemAnonymous(this.authoInput.workOrderLine.itemId);

        if (this.authoInput.workOrderItemType) {
            this.filteredRepairTypes = this.repairTypes.filter(i => !i.restrictByWorkOrderItemTypes || (i.workOrderItemTypes && i.workOrderItemTypes.indexOf(this.authoInput.workOrderItemType.id) > -1));
        } else {
            this.filteredRepairTypes = this.repairTypes;
        }
        this.groups = [];
        for (const repairType of this.filteredRepairTypes) {
            let g = this.groups.find(i => i.group.toLocaleLowerCase() === (repairType.category ?? 'Other').toLocaleLowerCase());
            if (!g) {
                g = new GroupedRepairType();
                g.group = repairType.category ?? 'Other';
                g.repairTypes = [];
                this.groups.push(g);
            }

            g.repairTypes.push(repairType);
        }
    }

    async selectType(type: RepairType) {
        this.repairTypeId = type.id;
        if (this.authoInput) {
            this.authoInput.repairType = type;
        }
        if (!this.authoInput.authorizationWarnings) {
            this.authoInput.authorizationWarnings = [];
        }


        const repairType = type;

        const items = await this.authoApi.getWorkOrderLineAuthorizationRepairItemsByLineId(this.authoInput.workOrderLine.id);

        if (this.authoInput?.costLines) {
            const linesToRemove = this.authoInput.costLines.filter(i => i.addedByStepId === 'initial');
            for (const line of linesToRemove) {
                this.authoInput.costLines.splice(this.authoInput.costLines.indexOf(line), 1);
            }
        }
        const costLines: CostLine[] = [];

        for (const authItem of repairType.repairItems) {
            const costLine = new CostLine();
            const foundItem = items.find(i => i.id === authItem.id);
            costLine.authorizationRepairItemSelector = foundItem;
            if (foundItem) {
                costLine.amount = (foundItem.contractorCost ?? foundItem.priceRangeMin) ?? 0;
                costLine.qty = 1;
                costLine.defaultItem = true;
                costLine.description = foundItem.name;
                costLine.id = UtilitiesService.newid();
                costLine.repairItemId = foundItem.id;
                costLine.automaticallyAdded = true;
                costLine.addedByStepId = 'initial';
                costLine.authorizationRepairItemGroupId = foundItem.authorizationRepairItemGroupId;
                if (repairType.coveredType === 'Always' || authItem.coveredType === 'Always') {
                    costLine.authorizationRepairItemSelector.salesItemCoverageAuthorizationRepairItemId = 'covered-by-default';
                }
                costLines.push(costLine);
                if (!foundItem.companyNeverProvides) {
                    if (foundItem.companyPrefersToProvide || this.authoInput.costLines.find(i => i.companyProvidesPart)) {
                        costLine.companyProvidesPart = true;
                        for (const existingLine of costLines) {
                            if (!existingLine.companyProvidesPart && !existingLine.authorizationRepairItemSelector?.companyNeverProvides) {
                                existingLine.companyProvidesPart = true;
                            }
                        }
                    } else {
                        const companyProvided = costLines.find(i => i.companyProvidesPart);
                        if (companyProvided) {
                            costLine.companyProvidesPart = true;
                        }
                    }
                }
            }
        }

        if (!this.authoInput.costLines) {
            this.authoInput.costLines = [];
        }

        if (repairType.laborHours) {

            const costLine = new CostLine();
            costLine.amount = repairType.calcLaborAsHourlyRate ? this.authoInput.laborRate : repairType.laborHours;
            costLine.qty = repairType.calcLaborAsHourlyRate ? repairType.laborHours : 1;
            costLine.defaultItem = true;
            costLine.calcLaborAsHourlyRate = repairType.calcLaborAsHourlyRate;
            costLine.description = 'Labor';
            costLine.id = UtilitiesService.newid();
            costLine.repairItemId = 'labor';
            costLine.automaticallyAdded = true;
            costLine.allowQuantityEntry = true;
            costLine.addedByStepId = 'initial';
            costLine.companyProvidedAvailable = false;
            costLines.push(costLine);
            costLine.companyProvidesPart = false;
            costLine.authorizationRepairItemSelector = new WorkOrderLineAuthorizationRepairItem();
            costLine.authorizationRepairItemSelector.companyNeverProvides = true;
            costLine.authorizationRepairItemSelector.id = 'labor';
            costLine.authorizationRepairItemSelector.priceRangeMax = costLine.amount;
            costLine.authorizationRepairItemSelector.name = 'Labor';
            costLine.authorizationRepairItemSelector.companyNeverProvides = true;
            costLine.authorizationRepairItemSelector.salesItemCoverageAuthorizationRepairItemId = 'Labor';
        }

        for (const costLine of costLines) {
            this.authoInput.costLines.push(costLine);
        }
        const guidelinePrice = await this.authoApi.getGuidelinePriceForRepairType(this.authoInput.workOrderLine.id, repairType.id);

        if (guidelinePrice) {
            if (guidelinePrice.price) {
                const trigger = new AuthorizationReviewTrigger();
                trigger.id = UtilitiesService.newid();
                trigger.type = 'TOTAL_PARTS_AND_AUTHO';
                trigger.value = guidelinePrice.price;
                trigger.description = 'Over guideline price';
                trigger.stepId = 'initial';
                this.authoInput.authorizationWarnings.push(trigger);
            }
            if (guidelinePrice.outOfPocket) {
                const trigger = new AuthorizationReviewTrigger();
                trigger.description = 'Over out of pocket guideline';
                trigger.id = UtilitiesService.newid();
                trigger.stepId = 'initial';
                trigger.value = guidelinePrice.outOfPocket;
                this.authoInput.authorizationWarnings.push(trigger);
            }
        }
        if (repairType.alwaysWarn) {
            const trigger = new AuthorizationReviewTrigger();
            trigger.type = 'MANUAL_REVIEW';
            trigger.description = repairType.alwaysWarnMessage;
            trigger.id = UtilitiesService.newid();
            trigger.stepId = 'initial';
            this.authoInput.authorizationWarnings.push(trigger);
        }

        if (repairType.warnIfDefaultItemsChange) {
            const trigger = new AuthorizationReviewTrigger();
            trigger.type = 'DEFAULT_PARTS_ALTERED';
            trigger.id = UtilitiesService.newid();
            trigger.stepId = 'initial';
            this.authoInput.authorizationWarnings.push(trigger);
        }
        if (this.authoInput.salesItemCoverageWorkOrderItem && repairType.coveredType === 'Conditional') {
            if (this.authoInput.salesItemCoverageWorkOrderItem.coveredRepairTypes.indexOf(repairType.id) === -1) {
                const trigger = new AuthorizationReviewTrigger();
                trigger.description = `Repair type: ${repairType.name} is not covered`;
                trigger.type = 'MANUAL_REVIEW';
                trigger.id = UtilitiesService.newid();
                trigger.stepId = 'initial';
                this.authoInput.authorizationWarnings.push(trigger);
            }
        } else if (repairType.coveredType === 'Never') {
            const trigger = new AuthorizationReviewTrigger();
            trigger.description = `Repair type: ${repairType.name} is not covered`;
            trigger.type = 'MANUAL_REVIEW';
            trigger.id = UtilitiesService.newid();
            trigger.stepId = 'initial';
            this.authoInput.authorizationWarnings.push(trigger);
        }
        this.repairTypeIdChange.emit(this.repairTypeId);
    }
}

class GroupedRepairType {
    repairTypes: RepairType[];
    group: string;
}
