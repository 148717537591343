import { Injectable } from '@angular/core';
import { ApiService, ResultExtractionType } from '@cogent/client/api';
import { AuthorizationsQueueItem } from '@cogent/shared/models/authorizations/authorizations-queue-item.model';
import { WorkOrderLineSummaryClient } from '@cogent/shared/models/service/work-order-line-summary.model';
import {  AuthorizationRepairItemGroup, AuthorizationRepairItemSummary, AuthorizationRule, ContractorAuthorizationRepairItem, CustomFunction, DefaultAuthorizationRepairItemsGroup, InventoryPart, Item, RepairItemAttribute, RepairItemAttributeValue, RepairType, RepairTypeGuidelinePrice, RepairTypeGuidelinePriceSummary, SalesItemCoverageAuthorizationRepairItem, SalesItemCoverageWorkOrderItem, SalesItemCoverageWorkOrderItemSelector, Trade, WorkOrderItem, WorkOrderItemType, WorkOrderItemTypeDiagram, WorkOrderLineAuthorization, WorkOrderLineAuthorizationRepairItem } from '@upkeeplabs/models/cogent';
import { AuthorizationLine, AuthorizationLineAttribute } from '@cogent/shared/models/authorizations/authorization-line.model';
import { SalesItemCoverageWorkOrderItemClient } from '@cogent/client/shared/models/plans/sales-item-coverage-work-order-item-client.model';

@Injectable({ providedIn: 'root' })
export class AuthorizationsApiService {
    deleteContractorAuthorizationRepairItem(id: string) {
        return this.api.deleteNode(`ContractorAuthorizationRepairItem/${id}`);
    }
    updateContractorAuthorizationRepairItem(item: ContractorAuthorizationRepairItem) {
        return this.api.postNode('ContractorAuthorizationRepairItem', item);
    }

    deleteWorkOrderItemTypeDiagram(id: string) {
        return this.api.deleteNode(`WorkOrderItemTypeDiagram/${id}`);
    }

    undeleteWorkOrderItemTypeDiagram(id: string) {
        return this.api.unDeleteNode(`WorkOrderItemTypeDiagram/${id}`);
    }

    getWorkOrderItemAuthorizationFunctionInstance(id: string) {
        return this.api.getSingleNode(`work-order-item/authorization-function/${id}`, null,()=> new CustomFunction());
    }

    getWorkOrderItemTypeDiagrams(workOrderItemTypeId: string) {
        return this.api.getArrayNode(`WorkOrderItemTypeDiagram`, {WorkOrderItemTypeId_eq: workOrderItemTypeId}, ()=> new WorkOrderItemTypeDiagram());
    }

    saveWorkorderItemTypeDiagram(diagram: WorkOrderItemTypeDiagram) {
        return this.api.postNode(`WorkOrderItemTypeDiagram`, diagram);
    }

    saveWorkOrderItemTypeDiagramFile(id: string, base64: string) {
        return this.api.postNode(`authorization/save-work-order-item-type-diagram-blob/${id}`, {base64});
    }


    deleteAuthorization(id: string) {
        return this.api.deleteNode(`WorkOrderLineAuthorization/${id}`);
    }
    undeleteAuthorization(id: string) {
        return this.api.unDeleteNode(`WorkOrderLineAuthorization/${id}`);
    }
    getWorkOrderItemTypesByWorkOrderItemId(id: string) {
        return this.api.getArrayNode(`WorkOrderItemType`, { workOrderItemId_eq: id, orderby: 'sort,name' }, () => new WorkOrderItemType());
    }
    getWorkOrderItemById(workOrderItemId: string) {
        return this.api.getSingleNode(`WorkOrderItem/${workOrderItemId}`, null, () => new WorkOrderItem());
    }
    getWorkOrderLineAuthorizations(id: string) {
        return this.api.getArrayNode('WorkOrderLineAuthorization', { workOrderLineId_eq: id, orderby: 'CreatedDate desc' }, () => new WorkOrderLineAuthorization());
    }
    updateRepairTypeIndex(repairType: RepairType) {
        return this.api.patchNode(`RepairType/${repairType.id}`, { sort: repairType.sort });
    }
    searchAuthorizationRepairItems(searchFor: string, workOrderItemId: string = null): Promise<AuthorizationRepairItemSummary[]> {
        let params: any = { name_like: searchFor };
        if (workOrderItemId) {
            params['workOrderItemId_eq'] = workOrderItemId;
        }
        return this.api.getArrayNode(`AuthorizationRepairItemSummary`, params, () => new AuthorizationRepairItemSummary());
    }


    constructor(private api: ApiService) { }

    getRepairItemAttribute(attributeId: string) {
        return this.api.getSingleDotNet(`RepairItemAttribute/${attributeId}`, null, () => new RepairItemAttribute());
    }

    deleteSalesItemCoverageAuthorizationRepairItem(id: string) {
        return this.api.deleteNode(`SalesItemCoverageAuthorizationRepairItem/${id}`)
    }

    getTotalSpentForPolicy(policyId: string) {
        return this.api.getSingleNode(`authorization/total-spent-on-policy/${policyId}`);
    }

    getLaborRate(workOrderLineId: string) {
        return this.api.getSingleNode(`authorization/labor-rate/${workOrderLineId}`);
    }

    getTripCharge(workOrderLineId: string) {
        return this.api.getSingleNode(`authorization/work-order-item-trip-charge/${workOrderLineId}`);
    }

    getContractorAuthorizationRepairItems(entityId: string, workOrderItemId: string) {
        return this.api.getArrayNode(`authorization/contractor-authorization-repair-items/${entityId}/${workOrderItemId}`, null, () => new ContractorAuthorizationRepairItem());
    }

    getContractorCoveredItems(entityId: string) {
        return this.api.getArrayNode(`authorization/contractor-covered-items/${entityId}`, null, () => new WorkOrderItem());
    }

    saveContractorAuthorizationRepairItem(item: ContractorAuthorizationRepairItem) {
        return this.api.postNode(`ContractorAuthorizationRepairItem`, item);
    }

    getWorkOrderItemAuthorizationFunction(id: string) {
        return this.api.getSingleNode(`WorkOrderItem/${id}`, 'select: authorizationFunctionId', () => new WorkOrderItem());
    }

    // getAuthorizationRepairTypeFunctionDefinition(repairTypeId: string) {
    //     return this.api.getSingleNode(`Authorization/repair-type/${repairTypeId}`, null,()=> new RepairType());
    // }

    getRepairTypesForWorkOrderItemAnonymous(workOrderItemId: string) {
        return this.api.getArrayNode(`Authorization/repair-types/${workOrderItemId}`, null, () => new RepairType());
    }

    getRepairTypesForWorkOrderItem(workOrderItemId: string) {
        return this.api.getArrayNode('RepairType', { workOrderItems_like: workOrderItemId, orderby: 'sort' }, () => new RepairType());
    }

    saveRepairType(repairType: RepairType) {
        return this.api.postNode(`RepairType`, repairType);
    }

    updateSalesItemCoverageWorkOrderItemRepairTypes(salesItemCoverageWorkOrderItemId: string, coveredRepairTypes: string) {
        return this.api.patchNode(`SalesItemCoverageWorKOrderItem/${salesItemCoverageWorkOrderItemId}`, { coveredRepairTypes });
    }

    saveRepairTypeGuidelinePrice(price: RepairTypeGuidelinePrice) {
        return this.api.postNode(`RepairTypeGuidelinePrice`, price);
    }

    getRepairTypeGuidelinePrices(repairTypeId: string) {
        return this.api.getArrayNode(`RepairTypeGuidelinePriceSummary`, { repairTypeId_eq: repairTypeId }, () => new RepairTypeGuidelinePriceSummary());
    }

    deleteRepairType(repairTypeId: string) {
        return this.api.deleteNode(`RepairType/${repairTypeId}`);
    }

    unDeleteRepairType(repairTypeId: string) {
        return this.api.unDeleteNode(`RepairType/${repairTypeId}`);
    }

    getAuthorizationsQueue(): Promise<AuthorizationsQueueItem[]> {
        return this.api.getArrayDotNet(
            'users/TaskQueue/7F01D5CC-58BC-4CB1-BCBF-2067DC0EA3E9/UnassignedTasks',
            null, () => new AuthorizationsQueueItem());
    }

    getAuthorizationRules(): Promise<AuthorizationRule[]> {
        return this.api.getArrayNode('AuthorizationRule',
            { orderby: 'sort,name' }, () => new AuthorizationRule());
    }

    saveWorkOrderLineAuthoArgs(workOrderLineId: string, authorizationRequestArgs: string) {
        return this.api.patchNode(`workOrderLine/${workOrderLineId}`, { authorizationRequestArgs });
    }
    saveAuthorizationRule(rule: AuthorizationRule): Promise<string> {
        return this.api.postIdNode('AuthorizationRule', rule);
    }

    searchForItemByActionPolicyAndWorkOrderItem(searchFor: string, action: string, policyId: string, workOrderItemId: string): Promise<Item[]> {
        return this.api.getArrayDotNet(`item/find/${action}/${policyId}/${workOrderItemId}`, { searchFor, orderby: 'Number,Description' }, () => new Item());
    }

    getUnusedInventoryParts() {
        return this.api.getArrayNode(`InventoryPart`, { purchaseOrderId_eq: '{{null}}', quantityRemaining_gt: 0, orderby: 'partNumber' }, () => new InventoryPart());
    }

    getWorkOrderLineAuthorizationRepairItemsByLineId(workOrderLineId: string) {
        return this.api.getArrayNode(`authorization/authorization-repair-items-by-line/${workOrderLineId}`, null, () => new WorkOrderLineAuthorizationRepairItem());
    }

    saveWorkOrderLineAuthorization(workOrderLineAuthorization: WorkOrderLineAuthorization) {
        workOrderLineAuthorization.fullArgs = JSON.stringify(workOrderLineAuthorization.args);
        console.log(workOrderLineAuthorization);
        return this.api.postNode(`WorkOrderLineAuthorization`, workOrderLineAuthorization);
    }

    getSalesItemCoverageWorkOrderItemSelectors(workOrderItemId: string) {
        return this.api.getArrayNode(`authorization/sales-item-coverage-work-order-item-selectors/${workOrderItemId}`, null, () => new SalesItemCoverageWorkOrderItemSelector());
    }

    getSalesItemcoverageAuthorizationRepairItemSelectors(authorizationRepairItemId: string) {
        return this.api.getArrayNode(`authorization/sales-item-coverage-authorization-repair-item-selectors/${authorizationRepairItemId}`, null, () => new SalesItemCoverageWorkOrderItemSelector());
        //
    }

    getSalesItemCoverageWorkOrderItem(salesItemCoverageId: string, workOrderItemId: string) {
        return this.api.getSingleNode(`authorization/sales-item-coverage-work-order-item/${salesItemCoverageId}/${workOrderItemId}`, null, () => new SalesItemCoverageWorkOrderItemClient());
    }

    saveInventoryPart(part: InventoryPart) {
        return this.api.postNode(`InventoryPart`, part);
    }

    getGuidelinePriceForRepairType(workOrderLineId: string, repairTypeId: string) {
        return this.api.getSingleNode(`authorization/guideline-price/${workOrderLineId}/${repairTypeId}`, null, () => new RepairTypeGuidelinePrice());
    }

    getDefaultAuthorizationRepairItemsGroups() {
        return this.api.getArrayNode(`DefaultAuthorizationRepairItemsGroup`, { orderby: name }, () => new DefaultAuthorizationRepairItemsGroup());
    }


    getDefaultAuthorizationRepairItemsGroup(id: string) {
        return this.api.getSingleNode(`DefaultAuthorizationRepairItemsGroup/${id}`, null, () => new DefaultAuthorizationRepairItemsGroup());
    }

    saveDefaultAuthorizationRepairItemsGroup(item: DefaultAuthorizationRepairItemsGroup) {
        return this.api.postNode('DefaultAuthorizationRepairItemsGroup', item);
    }

    deleteDefaultAuthorizationRepairItemsGroup(id: string) {
        return this.api.deleteNode(`DefaultAuthorizationRepairItemsGroup/${id}`);
    }

    unDeleteDefaultAuthorizationRepairItemsGroup(id: string) {
        return this.api.unDeleteNode(`DefaultAuthorizationRepairItemsGroup/${id}`);
    }

    deleteInventoryPart(partId: string) {
        return this.api.deleteNode(`InventoryPart/${partId}`);
    }

    undeleteInventoryPart(partId: string) {
        return this.api.unDeleteNode(`InventoryPart/${partId}`);
    }

    getReplacementItemsByAttributes(workOrderItemId: string, attributes: string[]) {
        const params: any = { replacesWorkOrderItemId_eq: workOrderItemId };

        for (const attribute of attributes) {
            if (params.where) {
                params.where += ' AND ';
            }
            if (!params.where) {
                params.where = '';
            }
            params.where += `Attributes.Contains("${attribute}")`;
        }

        return this.api.getArrayDotNet(`item`, params, () => new Item());
    }

    deleteAuthorizationRule(rule: AuthorizationRule): Promise<void> {
        return this.api.deleteNode('AuthorizationRule/' + rule.id);
    }

    undeletedRule(rule: AuthorizationRule): Promise<void> {
        return this.api.unDeleteNode('AuthorizationRule/' + rule.id);
    }

    findItemsByType(type: string): Promise<Item[]> {
        return this.api.getArrayDotNet('item/find/' + type, { searchFor: '' });
    }

    completeAuthorizationTask(workOrderId: string, taskId: string) {
        return this.api.patchDotNet(`WorkOrder/${workOrderId}/complete-authorization-task/${taskId}`, null);
    }

    completeWorkOrderLineAuthorizationTask(workOrderLineId: string, taskId: string) {
        return this.api.patchDotNet(`WorkOrder/${workOrderLineId}/complete-work-order-line-authorization-task/${taskId}`, null);
    }

    removeFromManagerSignOff(workOrderId: string) {
        this.api.patchDotNet(`WorkOrder/${workOrderId}/remove-from-manager-sign-off`, null);
    }

    analyzeAuthorization(workOrderId: string) {
        return this.api.getArrayDotNet("WorkOrder/" + workOrderId + "/AnalyzeAuthorization");
    }

    updateAuthoAmount(workOrderLineId: string, amount: number) {
        return this.api.patchSingleNode(`WorkorderLine/${workOrderLineId}`, {
            authorizationAmount: amount,
            authorizationLimit: amount,
        });
    }

    async saveAuthorizationForWorkOrderLine(workOrderLine: WorkOrderLineSummaryClient) {
        const promises: Promise<any>[] = [];

        await this.api.patchSingleDotNet(`WorkOrderLine/${workOrderLine.id}`, { authorizationLabor: workOrderLine.authorizationLabor });

        const existingLines = await this.getAuthorizationLines(workOrderLine.id);
        for (const line of existingLines) {
            if (!workOrderLine.lines.find(l => l.id === line.id)) {
                promises.push(this.api.deleteDotNet("AuthorizationLine/" + line.id));
            }
        }
        for (const line of workOrderLine.lines) {
            if (line.complete) {
                if (line.isChanged) {
                    promises.push(this.api.postIdDotNet("AuthorizationLine", line));
                }
                for (const attribute of line.attributes) {
                    if (attribute.isChanged) {
                        promises.push(this.api.postIdDotNet("AuthorizationLineAttribute", attribute));
                    }
                }
            }
        }

        await Promise.all(promises);
    }

    async saveAuthorization(workOrderId: string, authorizationLines: AuthorizationLine[], laborTotal: number) {
        const promises: Promise<any>[] = [];

        await this.api.patchSingleDotNet(`WorkOrder/${workOrderId}`, { laborTotal: laborTotal });

        const existingLines = await this.getAuthorizationLines(workOrderId);
        for (const line of existingLines) {
            if (!authorizationLines.find(l => l.id === line.id)) {
                promises.push(this.api.deleteDotNet("AuthorizationLine/" + line.id));
            }
        }
        for (const line of authorizationLines) {
            if (line.complete) {
                if (line.isChanged) {
                    promises.push(this.api.postIdDotNet("AuthorizationLine", line));
                }
                for (const attribute of line.attributes) {
                    if (attribute.isChanged) {
                        promises.push(this.api.postIdDotNet("AuthorizationLineAttribute", attribute));
                    }
                }
            }
        }

        await Promise.all(promises);
    }

    async getAuthorizationLines(workOrderLineId: string) {
        const lines = await this.api.getArrayDotNet("AuthorizationLine/Detail", { workOrderLineId }, () => new AuthorizationLine());
        for (const line of lines) {
            line.attributes = this.api.processSubResult(line.attributes, () => new AuthorizationLineAttribute(), ResultExtractionType.Array);
            for (const attribute of line.attributes) {
                attribute.availableValues = this.api.processSubResult(attribute.availableValues, () => new RepairItemAttributeValue(), ResultExtractionType.Array);
            }
        }
        return lines;

    }

    getAuthorizationRepairItems(workOrderItemId: string) {
        return this.api.getArrayNode(`AuthorizationRepairItemSummary`, { workOrderItemId_eq: workOrderItemId, orderby: 'name' }, () => new AuthorizationRepairItemSummary());
    }

    getAuthorizationRepairItemGroups(tradeId: string) {
        return this.api.getArrayNode('AuthorizationRepairItemGroup', { tradeId_eq: tradeId, orderby: 'name' }, () => new AuthorizationRepairItemGroup());
    }

    saveAuthorizationRepairItemGroup(group: AuthorizationRepairItemGroup) {
        return this.api.postNode('AuthorizationRepairItemGroup', group);
    }

    deleteAuthorizationRepairItemGroup(id: string) {
        return this.api.deleteNode(`AuthorizationRepairItemGroup/${id}`);
    }

    unDeleteAuthorizationRepairItemGroup(id: string) {
        return this.api.unDeleteNode(`AuthorizationRepairItemGroup/${id}`);
    }

    getTradeFromWorkOrderItem(workOrderItemId: string) {
        return this.api.getSingleNode(`authorization/work-order-item-trade/${workOrderItemId}`, null, () => new Trade());
    }

    saveAuthorizationRepairItem(authorizationRepairItem: AuthorizationRepairItemSummary) {
        return this.api.postNode(`AuthorizationRepairItem`, authorizationRepairItem);
    }

    deleteAuthorizationRepairItem(id: string) {
        return this.api.deleteNode(`AuthorizationRepairItem/${id}`);
    }

    unDeleteAuthorizationRepairItem(id: string) {
        return this.api.unDeleteNode(`AuthorizationRepairItem/${id}`);
    }

    getWorkOrderItems(): Promise<WorkOrderItem[]> {
        return this.api.getArrayNode(`WorkOrderItem`, { orderby: 'name' }, () => new WorkOrderItem());
    }

    getSalesItemCoverageAuthorizationRepairItems(salesItemCoverageId: string) {
        return this.api.getArrayNode(`SalesItemCoverageAuthorizationRepairItem`, { salesItemCoverageId_eq: salesItemCoverageId }, () => new SalesItemCoverageAuthorizationRepairItem());
    }

    saveSalesItemCoverageAuthorizationRepairItem(salesItemCoverageAuthorizationRepairItem: SalesItemCoverageAuthorizationRepairItem) {
        return this.api.postNode('SalesItemCoverageAuthorizationRepairItem', salesItemCoverageAuthorizationRepairItem);
    }



}